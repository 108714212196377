import React from 'react'
import Footer from './Footer'
const ReturnPolicy = () => {
    return (
        <>
            <center><h1>Money Refund Policy</h1></center>
            <p>Overview

                At KomalMan Power Services, we are committed to ensuring customer satisfaction. If you are not completely satisfied with your purchase, we offer a 15-day money refund policy.</p>
            <p>
                <h1>Eligibility</h1>

                To be eligible for a refund, the following conditions must be met:

                The request for a refund must be made within 15 days from the date of purchase.
                The product or service must be in its original condition and packaging.
                Proof of purchase, such as a receipt or order confirmation, must be provided.
            </p>
            <p>
                <h1>Refund Process
                </h1>
                To request a refund, please follow these steps:

                Contact our customer service team at [contact email/phone number] to initiate the refund process.
                Provide details of your purchase, including the order number and reason for the refund request.
                Our customer service team will review your request and provide further instructions on returning the product or cancelling the service.
                Once the returned product is received and inspected, we will notify you of the approval or rejection of your refund.
                If approved, the refund will be processed within [number of days] and credited to the original method of payment.
            </p>
            <Footer></Footer>

        </>
    )
}

export default ReturnPolicy