import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navb from './components/Navb';
import Home from './components/Home'
import About from './components/About.jsx'
import Contact from './components/Contact.jsx'
import HouseMaid from './components/HouseMaid';
import Cook from './components/Cook.jsx'
import BabySitter from './components/BabySitter';
import PatientCare from './components/PatientCare';
import MaleServent from './components/MaleServent';
import AllRounder from './components/AllRounder';

import HeroSection from './components/HeroSection';
import CheckOut from './components/CheckOut.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import ReturnPolicy from './components/ReturnPolicy.jsx';
import DeliveryPolocy from './components/DeliveryPolocy.jsx';
import TermCondition from './components/TermCondition.jsx';

function App() {
  return (
    <>
      <Navb></Navb>
      <HeroSection></HeroSection>


      <BrowserRouter>

        <Routes>
          <Route path='/' element={<Home></Home>}></Route>
          <Route path='/about' element={<About></About>}></Route>
          <Route path='/contact' element={<Contact></Contact>}></Route>
          <Route path='/housemaid' element={<HouseMaid></HouseMaid>}></Route>
          <Route path='/cook' element={<Cook></Cook>}></Route>
          <Route path='/babysitter' element={<BabySitter></BabySitter>}></Route>
          <Route path='/patientcare' element={<PatientCare></PatientCare>}></Route>
          <Route path='/maleservent' element={<MaleServent></MaleServent>}></Route>
          <Route path='/allrounder' element={<AllRounder></AllRounder>}></Route>
          <Route path='/checkout' element={<CheckOut></CheckOut>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy></PrivacyPolicy>} />
          <Route path='/return-policy' element={<ReturnPolicy></ReturnPolicy>} />
          <Route path='/delivery-policy' element={<DeliveryPolocy></DeliveryPolocy>}></Route>
          <Route path='/term-condition' element={<TermCondition></TermCondition>}></Route>

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
