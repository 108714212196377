import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const WhyChoose = () => {
    return (
        <>
            <section className="why-choose-us-section py-5" style={{ backgroundColor: '#f8f9fa' }}>
                <Container>
                    <h2 className="text-center mb-4">Why Choose Us</h2>
                    <Row className="justify-content-center">
                        <Col md={4} className="text-center mb-4">
                            <h3>Experienced Team</h3>
                            <p>We have a dedicated team of experienced professionals.</p>
                        </Col>
                        <Col md={4} className="text-center mb-4">
                            <h3>Quality Service</h3>
                            <p>We prioritize quality and strive to exceed our client's expectations.</p>
                        </Col>
                        <Col md={4} className="text-center mb-4">
                            <h3>Customer Satisfaction</h3>
                            <p>Our goal is to ensure complete satisfaction for all our customers.</p>
                        </Col>
                        <Col md={4} className="text-center mb-4">
                            <h3>Affordable Pricing</h3>
                            <p>We offer competitive prices without compromising on the quality of our services</p>
                        </Col>
                        <Col md={4} className="text-center mb-4">
                            <h3>Easy Booking Process </h3>
                            <p>Our booking process is simple and hassle-free, making it easy for you to get the domestic help you need.</p>
                        </Col>
                        <Col md={4} className="text-center mb-4">
                            <h3>Customizable Solutions </h3>
                            <p>We understand that every household has unique needs, and we offer customizable solutions to meet those needs..</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default WhyChoose