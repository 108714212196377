import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>
            <p>
                KomalMan Power Services ("us", "we", or "our") operates the KomalMan Power Services website (the "Service").

                This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.

                We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.
                Information Collection and Use

                We collect several different types of information for various purposes to provide and improve our Service to you.
                Types of Data Collected
                Personal Data

                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:

                Email address
                First name and last name
                Phone number
                Address, State, Province, ZIP/Postal code, City
                Cookies and Usage Data
            </p>
        </>
    )
}

export default PrivacyPolicy