import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AllRndImage from '../asset/We-are-Domestic-Help-min-1024x546-1.png'

const HappyCust = () => {

    return (
        <>
            <section className="about-section py-5">
                <Container>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Image src={AllRndImage} alt="About Us" fluid />
                        </Col>
                        <Col md={6}>
                            <h2>About Us</h2>
                            <p>
                                KomalMan Power Supplier is a leading provider of reliable and efficient power solutions. With years of experience in the industry, we specialize in supplying high-quality power generators, transformers, and other electrical equipment to meet the needs of our clients.
                            </p>
                            <p>
                                Our mission is to ensure uninterrupted power supply to our customers, enabling them to operate their businesses smoothly and efficiently. We are committed to delivering exceptional service and exceeding our client's expectations.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HappyCust