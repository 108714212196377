import React from 'react'
import Footer from './Footer'

const TermCondition = () => {
    return (
        <>
            <center><h1>Terms & Conditions</h1></center>
            <p>
                komal Power service is a placement consultancy Agency operating in all Major Metro City Dess India
            </p>
            <p>We offer a wide range of placement consultancy services including but not limited to Housemaids, Cooks, Babysitter, Nannies, Patient Caregivers, Elder Caregivers, Japa maid and more.</p>
            <p>We charge a service fee of one month's salary plus 18% GST paid by the client for a period of one year from the date of registration. Kindly note that the said service fee does not include the Candidate's salary and the same is only our service fee and that you shall be responsible to pay the Candidates salary on timely basis.</p>
            <p>If you choose to retain the same Candidate beyond a period of 1 (one) year then you shall be required to renew the contract with us as per our company policy</p>
            <p>50% of our service fee is required to be paid at the time of registration and the balance 50% shall be due and payable upon finalisation the Candidate. We also accept 100% up front payment of our service fee</p>
            <p>Your contact with us is valid for a period of 1 (one) year from the date of your registration and you are eligible for up to three replacements within that year</p>
            <p>7. For your safety, we advise you to inspect the Candidate's bag and/or belongings upon arrival and departure

                from your home/premises. 8. If there are any pending salary issue with your current and/or previous Candidate, we shall not process any of

                your request for replacement of the Candidate. Replacement requests shall only be accepted after the Candidate

                has left your employment.</p>
            <p>Once you hire a Candidate through Komalmanpwerservices.com, it is mandatory for you to inform us of the said hiring.</p>
            <p>Please note that if you choose to cancel your contract for any reason during the contract period, your registration amount shall not be refunded for any reason whatsoever</p>
            <p>Upon full payment of our service fee, you will receive an invoice and the Candidates documents from us.</p>
            <p>The instructions and the contents of these terms and conditions ara confidential and you shall not share the same with any third Party.</p>
            <p>If we introduce you to a Candidate, you will have the opportunity to communicate with the Candidate through phone or video call. If you decide to proceed, we will sand the Candidate to your location along with an executive for joining, and you will need the balance service fees on the same day.</p>
            <p>14. While we handle the documentation process, we strongly encourage every client to carry out the police

                verification of the Candidate. We will provide you with the necessary documents for this purpose.</p>
            <p>15. to pay We kindly request you to thoroughly check, verily and confirm the solutions that we offer and that

                they meet your specific</p>
            <p>
                16. In the event you choose to cancel your contract with us for any reason, 50% of the service fees paid by you to

                us will be refunded and sent to you via courier within 45 days. The said refund policy is applicable only if we are unable to arrange


            </p>
            <p>
                17. We shall not be held responsible for any situation and/or misconduct or any money/product given that shall be at your own risk.
            </p>
            <p>
                18. requirements and preferences. Prior to hiring any Candidate, we strongly recommend that you carefully review and ensure your satisfaction with the work of


            </p>
            <p>
                19. your safely at your own cost. Please note that at the time of recruiting the Candidate, only the tasks desa ibed in your service request shall be accepted.


            </p>
            <p>parties. The place of arbitration shall be Jaipur. The arbitration shall be conducted in accordance with the</p>
            <p>provisions of the Arbitration and Conciliation Act, 1995 or any re-enactment or statutory modification thereof.</p>
            <p>The language of arbitration shall be Hindi.</p>
            <Footer></Footer>
        </>
    )
}

export default TermCondition