import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logikomal from '../asset/komal logo.png'



const Footer = () => {
    return (
        <>
            <footer className="footer-section py-4 mt-5">
                <Container>
                    <Row>
                        <Col md={3} className="mb-3">
                            <img src={logikomal} alt="Company Logo" className="img-fluid" />
                        </Col>
                        <Col md={6} className="mb-3">
                            <p>Komal Man Power Services is a leading provider of manpower solutions. We specialize in connecting businesses with skilled professionals to fulfill their staffing needs.</p>
                            <p>Email:support@komalmanpowerservices.com</p>
                            <p>Contact:9119308719</p>
                        </Col>
                        <Col md={3} className="mb-3">
                            <ul className="list-unstyled">
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/return-policy">Return Policy</a></li>
                                <li><a href="/delivery-policy">Delivery Policy</a></li>
                                <li><a href='/term-condition'>Term & Condition</a></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer