
import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'
import HouseMaidImage from '../asset/housemaid.jpg';
import HouseMaid from './HouseMaid';
import CookImage from '../asset/cook.jpeg'
import BabySitter from '../asset/girl-feeding-baby-brother-.jpg'
import PatientCareImage from '../asset/doctor-talking-with-her-elder-patient-min1-1536x1050.jpg';
import MaleServantImage from '../asset/male.jpeg'
import DeepCleaningImage from '../asset/deepcleaning.jpg'
import DrivarImage from '../asset/driver.jpg'
import WhyChoose from './WhyChoose';
import HappyCust from './HappyCust';
import Footer from './Footer';
import CheckOut from './CheckOut';
import { Link } from 'react-router-dom';
const Home = () => {
    const handleClick = () => {
        window.open("https://razorpay.me/@komalmanpowerservices9241?amount=hs5%2BhsUaIlsmW%2BfZKlAvnw%3D%3D")
    }
    return (
        <>
            <section className="services-section ">
                <Container>
                    <h2 className="text-center mb-5">Our Services</h2>
                    <Row xs={1} md={2} lg={3} className="g-4">
                        <Col>
                            <Card className="h-100">
                                <Card.Img variant="top" src={HouseMaidImage} />
                                <Card.Body>
                                    <Card.Title>House Maid</Card.Title>
                                    <Card.Text>
                                        A maid’s hard work and attention to detail transform a house into a home
                                    </Card.Text>
                                    {/* <Link to='/checkout' variant="primary" >Book Now</Link> */}
                                    <button onClick={handleClick} className='btn btn-primary'>Book Now</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="h-100">
                                <Card.Img variant="top" src={CookImage} />
                                <Card.Body>
                                    <Card.Title>CooK</Card.Title>
                                    <Card.Text>
                                        Bringing flavor to life, one dish at a time – Cook with passion, serve with love!
                                    </Card.Text>
                                    {/* <Link to='/checkout' variant="primary" >Book Now</Link> */}
                                    <button onClick={handleClick} className='btn btn-primary'>Book Now</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="h-100">
                                <Card.Img variant="top" src={BabySitter} />
                                <Card.Body>
                                    <Card.Title>Babysitter</Card.Title>
                                    <Card.Text>
                                        Babysitters are not just caretakers, they are memory-makers for children.
                                    </Card.Text>
                                    {/* <Link to='/checkout' variant="primary" >Book Now</Link> */}
                                    <button onClick={handleClick} className='btn btn-primary'>Book Now</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="h-100">
                                <Card.Img variant="top" src={DrivarImage} />
                                <Card.Body>
                                    <Card.Title>Driver services</Card.Title>
                                    <Card.Text>
                                        Our driver services provide safe and reliable transportation, ensuring your peace of mind and comfort.
                                    </Card.Text>
                                    {/* <Link to='/checkout' variant="primary" >Book Now</Link> */}
                                    <button onClick={handleClick} className='btn btn-primary'>Book Now</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="h-100">
                                <Card.Img variant="top" src={DeepCleaningImage} />
                                <Card.Body>
                                    <Card.Title>Deep Cleaning</Card.Title>
                                    <Card.Text>
                                        With our house cleaning services, you can enjoy a clean and tidy home without any hassle or stress. Our trained cleaners use eco-friendly products to ensure your safety and comfort..
                                    </Card.Text>
                                    {/* <Link to='/checkout' variant="primary" >Book Now</Link> */}
                                    <button onClick={handleClick} className='btn btn-primary'>Book Now</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="h-100">
                                <Card.Img variant="top" src={MaleServantImage} />
                                <Card.Body>
                                    <Card.Title>Male servant</Card.Title>
                                    <Card.Text>
                                        Our male servant services provide reliable and efficient help with all your household needs, ensuring your comfort and convenience.
                                    </Card.Text>
                                    {/* <Link to='/checkout' variant="primary" >Book Now</Link> */}
                                    <button onClick={handleClick} className='btn btn-primary'>Book Now</button>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* Add more services as needed */}
                    </Row>
                </Container>
            </section>
            <WhyChoose></WhyChoose>
            <HappyCust></HappyCust>
            <Footer></Footer>

        </>
    )
}

export default Home