import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';


const CheckOut = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        cardNumber: '',
        expDate: '',
        cvv: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to server)
        console.log(formData);
    };

    return (
        <>

            <Container className="py-5">
                <h2 className="mb-4">Checkout</h2>
                <Row>
                    <Col md={6}>
                        <h4>Shipping Information</h4>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="fullName">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="fullName">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={formData.fullName} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="fullName">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control type="number" name="Mobile Number" value={formData.fullName} onChange={handleChange} required />
                            </Form.Group>
                            {/* Add more form fields for shipping information as needed */}
                            <Button variant="primary" type="submit">Continue to Payment</Button>
                        </Form>
                    </Col>
                    <Col md={6}>
                        <h4>Payment Information</h4>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="cardNumber">
                                <Form.Label>Card Number</Form.Label>
                                <Form.Control type="text" name="cardNumber" value={formData.cardNumber} onChange={handleChange} required />
                            </Form.Group>
                            {/* Add more form fields for payment information as needed */}
                            <Button variant="primary" type="submit">Place Order</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default CheckOut