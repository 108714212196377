import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import cookImage from '../asset/cook.jpeg'
import '../App.css'


const HeroSection = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        // Your form submission logic here
    };
    return (
        <>
            <section className="hero-section">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h1>Welcome to KomalKomal Man Power Services</h1>
                            <p>
                                We provide Maid Servent,BabySitter,Aaya,CareTaker,Elderly Care,Home Nurse Services
                            </p>
                            <Button variant="primary">Learn More</Button>
                        </Col>
                        <Col md={6}>
                            {/* You can add an image or any other content in this column */}
                            <img
                                src={cookImage}
                                alt="Man Power Services"
                                className="img-fluid"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default HeroSection