import React from 'react'

const DeliveryPolocy = () => {
    return (
        <>
            <center>
                <h1>Delivery Policy</h1>
            </center>
            <p>
                Overview

                At Komal Man Power Services, we strive to provide efficient and timely delivery of our services to our clients. This delivery policy outlines the terms and conditions regarding the delivery of our manpower solutions.
            </p>
            <p>

            </p>
        </>
    )
}

export default DeliveryPolocy